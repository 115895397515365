import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Process = makeShortcode("Process");
const Box = makeShortcode("Box");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const InfoBlock = makeShortcode("InfoBlock");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-a-arte-da-conversa",
      "style": {
        "position": "relative"
      }
    }}>{`💬 A arte da conversa`}<a parentName="h1" {...{
        "href": "#-a-arte-da-conversa",
        "aria-label": " a arte da conversa permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <Video src="https://www.youtube-nocookie.com/embed/CCYw0QVoB_U" mdxType="Video" />
    <p>{`Este documentário apresenta Paul Myburgh, autor de ”`}<em parentName="p">{`The Bushmen Winter Has Come”`}</em>{`, que passou sete anos vivendo com os povos ancestrais Gwi no deserto do Kalahari, explorando antigas conversas existenciais.`}</p>
    <h2 {...{
      "id": "como-isso-se-aplica-ao-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`Como isso se aplica ao Kernel?`}<a parentName="h2" {...{
        "href": "#como-isso-se-aplica-ao-kernel",
        "aria-label": "como isso se aplica ao kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Existem muitos insights importantes para as conversas que cultivamos tanto aqui no Kernel quanto as que expandimos para horizontes mais distantes. Selecionamos algumas das principais citações, perguntas e ensinamentos que Paul oferece como forma de entender o que vem a seguir.`}</p>
    <Process mdxType="Process">
      <p>{`"`}<strong parentName="p">{`A qualidade da escuta determina a qualidade da fala.`}</strong>{`”`}</p>
      <p>{`"Quando as pessoas estão ouvindo, sou compelido a falar mais a verdade.”`}</p>
      <p>{`Devemos aspirar à soma positiva, conversas horizontais (9:14 no vídeo).`}</p>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Conversas horizontais:`}</strong>{` diálogo interativo entre colegas. Os participantes compartilham confiança, vivendo dentro do mesmo contexto. As conversas continuam e podem ser retomadas amanhã, na próxima semana ou algum tempo depois. Muito mais significado é compartilhado.`}</p>
        <blockquote>
          <p parentName="blockquote">{`"Tenho muito respeito pelas pessoas que pensam enquanto falam, e que param para pensar e acrescentar `}<strong parentName="p">{`significado que importa`}</strong>{`."`}</p>
        </blockquote>
        <p><strong parentName="p">{`Conversas verticais:`}</strong>{` Cada pessoa fala para expor seu ponto de vista - não se baseando no diálogo que já está acontecendo. Torna-se uma competição porque você está pensando no que dizer em vez de ouvir. Dificulta o ouvir e a troca de informações verdadeiras. Menos significado é compartilhado.`}</p>
      </Box>
      <p>{`Fazer perguntas melhores ao ouvir mais profundamente`}</p>
      <p>{`Que tipos de discurso realmente agregam valor?`}</p>
    </Process>
    <h2 {...{
      "id": "resumo",
      "style": {
        "position": "relative"
      }
    }}>{`Resumo`}<a parentName="h2" {...{
        "href": "#resumo",
        "aria-label": "resumo permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <blockquote>
      <p parentName="blockquote">{`“Vivemos em um mundo onde é moda jogar essas frases de efeito fofas um para o outro e pensar que sabemos das coisas. Fazemos perguntas sem realmente querer as respostas - tudo o que procuramos é uma solução rápida; uma solução conceitual para uma curiosidade que realmente não importa porque é apenas uma fantasia passageira. Não vivemos em um mundo onde temos conversas sobre coisas que realmente importam! Refiro-me a explorações sem fim da verdade fora de nós mesmos... "`}</p>
    </blockquote>
    <p>{`Qual é a verdade fora de você? Como você poderia criar ferramentas coletivas que incentivassem as pessoas a explorar essas ideias e se conectar de maneiras verdadeiramente significativas?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Temos mil alternativas para cada fundamento e passamos grande parte de nossas vidas preocupados com essas alternativas. Nossas vidas, portanto, tendem a ser muito mais `}<strong parentName="p">{`ligadas incidentalmente`}</strong>{` em vez de `}<strong parentName="p">{`relacionadas fundamentalmente`}</strong>{`. Com isso expressamos nossa separação: do mundo natural, dos fundamentos, do cosmos."`}</p>
    </blockquote>
    <p>{`Este é um jogo linguístico profundo: todos nós nos encontramos envolvidos em tarefas aparentemente insignificantes que constantemente atrapalham aquela coisa realmente profunda que você pretende aprender ou fazer. Por que é isso? Como podemos usar mais de nossa existência diária para aquilo que não está dependente e aprisionado em trivialidades, mas fundamentalmente relacionado a tudo ao nosso redor? Não é isso que uma rede faz?`}</p>
    <Flash mdxType="Flash">
    
      <Card mdxType="Card">
    
Que tipos de conversas construímos juntos no Kernel?
        <p>{`Horizontais`}</p>
      </Card>
    </Flash>
    <blockquote>
      <p parentName="blockquote">{`"Senti falta da `}<strong parentName="p">{`Voz Verdadeira`}</strong>{`".`}</p>
    </blockquote>
    <p>{`O que é Voz Verdadeira? Você já ouviu isso?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"As pessoas ouvem. Há pausas entre as falas que são preenchidas com o pensamento e com a compreensão."`}</p>
    </blockquote>
    <p>{`Quão confortável você se sente com o silêncio? Não é essa a real medida do companheirismo? Quão confortáveis são os silêncios?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"A conversa moderna não dá espaço para que as palavras se instalem no ouvinte `}{`[...]`}{` Tenho muito respeito pelas pessoas que pensam enquanto falam, e que param para pensar e acrescentar `}<strong parentName="p">{`o significado que importa`}</strong>{`."`}</p>
    </blockquote>
    <h2 {...{
      "id": "a-sede-de-verdade",
      "style": {
        "position": "relative"
      }
    }}>{`A sede de verdade`}<a parentName="h2" {...{
        "href": "#a-sede-de-verdade",
        "aria-label": "a sede de verdade permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <InfoBlock mdxType="InfoBlock">
      <p>{`"Fazer o que é justo com toda a alma e dizer a verdade. O que resta a você é aproveitar a vida, conectando cada coisa boa à outra, sem deixar o menor intervalo entre elas." - Pierre Hadot`}</p>
    </InfoBlock>
    <p>{`Como você pode cultivar o espaço dentro de si necessário para ouvir bem? O que realmente significa atribuir um significado consciente às palavras que você diz ou escreve, conectando cada coisa boa à seguinte? Como isso é amplificado quando essas palavras são códigos executáveis?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"A fala de alguém deve ser moldada pela qualidade do ouvinte. Então, quando você está me ouvindo, sou compelido a falar com mais sentido. Sou compelido a falar mais verdade. Em muitas conversas modernas, ninguém está ouvindo , então ninguém se importa com o que eles dizem `}{`[...]`}{` A qualidade de ouvir informa a qualidade de falar."`}</p>
    </blockquote>
    <p>{`Como podemos construir ferramentas e espaços digitais que incentivem a escuta de alta qualidade, em vez de apenas autopromoção sem sentido?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Você vai além da sede e entende que não existe sede. Ou você tem água ou não tem água. E no meio não há nada."`}</p>
    </blockquote>
    <p>{`Quais são os anseios fictícios em sua vida? Como você poderia chegar ao oposto deles para ver como o medo e o desejo realmente são vazios?`}</p>
    <h2 {...{
      "id": "velhos-presentes-de-novo",
      "style": {
        "position": "relative"
      }
    }}>{`Velhos presentes de novo`}<a parentName="h2" {...{
        "href": "#velhos-presentes-de-novo",
        "aria-label": "velhos presentes de novo permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <blockquote>
      <p parentName="blockquote">{`"A humanidade moderna não aprende sendo abençoada. Nosso caminho é diferente. Somos feitos para fazer perguntas. Para nós, o conhecimento é algo que se consegue com esforço, por livre arbítrio. A tarefa do ser humano moderno é claramente e definitivamente buscar e encontrar conhecimento. Não informações conceituais baratas, mas o conhecimento das próprias coisas. Quero dizer `}<strong parentName="p">{`conhecimento perceptivo.`}</strong>{` Não ideias rápidas, mas o conhecimento real das coisas. Sem brindes, sem soluções rápidas. Estradas difíceis . Cada alma humana dando cada passo."`}</p>
    </blockquote>
    <p>{`Você consegue se lembrar de momentos em sua vida em que "simplesmente sabia" alguma coisa? Qual era a qualidade e a textura desse conhecimento? Como podemos cultivar mais disso em nossas vidas e como podemos construir produtos que ajudem "toda alma humana a dar `}{`[mesmo que seja]`}{` um único passo" mais perto de suas próprias verdades?`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Pergunta: O que é necessário para co-criar conversas horizontais?`}</p>
        <p>{`Audição clara.`}</p>
      </Card>
    </Flash>
    <blockquote>
      <p parentName="blockquote">{`"Cada vez mais vejo o valor da conversa humana: o valor do que surge entre nós quando falamos de verdade com o que temos e o que buscamos. Todos nós. Quando vejo essa conversa humana que `}<strong parentName="p">{`deve`}</strong>{` ser novamente, há algo mais que é perguntado aqui. Que é que os seres humanos comecem a conversar por nós mesmos e com nós mesmos, para que possamos - mais uma vez - `}<strong parentName="p">{`encontrar o que era antiga comunidade em forma futura`}</strong>{`. E isso apenas sairá de uma conversa sobre o que sabemos e o que desejamos saber."`}</p>
    </blockquote>
    <p>{`Essa noção de "aquilo que desejamos saber" nos leva a uma `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://publicism.info/science/machines/9.html"
        }}>{`história surpreendente`}</a></strong>{` sobre outro par complementar de opostos: inteligência artificial e aumento da inteligência. É uma história que revela como o futuro da pesquisa agora é entendido como uma conversa: um diálogo contínuo sobre a tensão entre automatizar e aumentar a experiência humana. O que é mais fascinante é que`}</p>
    <blockquote>
      <p parentName="blockquote">{`A interação homem-máquina é uma forma de conversa, um processo dinâmico, no qual os participantes aprendem uns sobre os outros.`}</p>
    </blockquote>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Diálogo`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/mod0-img/david_bohm_and_jiddu_krishnamurti.jpg",
            "alt": "David Bohm"
          }}></img></p>
        <p>{`David Bohm foi um dos físicos teóricos mais importantes do século XX. Ele também era um amigo próximo de Jiddu Krishnamurti.`}</p>
        <p>{`"Com essa consciência coerente comum, temos um novo tipo de inteligência capaz de pensar em conjunto. Temos que começar com pessoas que sejam abertas o suficiente para iniciar o diálogo."`}</p>
        <Aligner center mdxType="Aligner">
          <Button to="https://www.bohmdialogue.org/#bohmdialogue" mdxType="Button">Participar</Button>
        </Aligner>
      </Box>
    </InfoBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      